<template>
    <div class="content">
        <div class="nav">
            <img class="firmLogo" src="../assets/img/firmlogo.png" alt="">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
                <el-menu-item index="/dream">梦想宸兴</el-menu-item>
                <el-menu-item index="/employment">就业创业</el-menu-item>
                <el-menu-item index="/OddJob">零工市场</el-menu-item>
                <el-menu-item index="/community">社区服务</el-menu-item>
                <el-menu-item index="/contact">用户案例</el-menu-item>
            </el-menu>
        </div>
        <div>
            <el-main>
                <router-view></router-view>
            </el-main>
        </div>
        <div class="footerNav">
            <HomeView  @childByValue="childByValue"></HomeView>
        </div>
    </div>
</template>
<script>

import HomeView from "@/views/HomeView";

export default {
  name: 'home',
  components: {
    HomeView
  },
  data() {
    return {
      activeIndex: ""
    }
  },
  created() {
    if (this.$route.path === '/') {
      this.activeIndex = '/dream'
      this.$router.push('/dream');
    } else if (this.$route.path === '/dream') {
      this.activeIndex = '/dream'
    } else if (this.$route.path === '/employment') {
      this.activeIndex = '/employment'
    } else if (this.$route.path === '/OddJob') {
      this.activeIndex = '/OddJob'
    } else if (this.$route.path === '/community') {
      this.activeIndex = '/community'
    } else if (this.$route.path === '/contact') {
      this.activeIndex = '/contact'
    }
  },
  methods: {
    handleSelect(e) {
      console.log((e))
    },
    childByValue(childValue){
      this.activeIndex = childValue
      this.$router.push(childValue);
      window.scrollTo({
        top: 0,
        behavior: 'instant' // 瞬间滚动
      });
    }
  }
}
</script>

<style scoped>
    body::-webkit-scrollbar {
        display: none !important;
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
    }

    .content {
        width: 100%;
        height: 100vh;
    }

    .nav {
        width: 100%;
        height: 9.5vh;
        background: #131B30;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
        position: relative;
    }

    .nav .firmLogo {
        position: absolute;
        top: 2vh;
        left: 35vh;
        z-index: 1;
        height: 5vh;
    }

    /deep/ .el-main {
        overflow: hidden;
        padding: 0 !important;
        margin: 0 !important;
    }

    /deep/ .el-menu {
        height: 88px;
        background-color: #131B30FF;
        display: flex;
        justify-content: center;
    }

    /deep/ .el-menu li {
        height: 9.5vh;
        line-height: 10vh;
    }

    /deep/ .el-menu--horizontal > .el-menu-item.is-active {
        font-size: 1.7vh !important;
    }

    /deep/ .el-dropdown-menu__item, .el-menu-item {
        font-size: 1.7vh !important;
    }

    /deep/ .el-dropdown-menu__item, .el-menu-item {
        padding: 0 2vh !important;
    }

    /deep/ .el-menu--horizontal > .el-menu-item.is-active {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-align: right;
    }

    /deep/ .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid #fff;
    }

    /deep/ .el-menu-item:focus, .el-menu-item:hover {
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/ .el-menu.el-menu--horizontal {
        border: none !important;
    }

    .footerNav {
        overflow: hidden;
    }
</style>
