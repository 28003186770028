<template>
    <div class="page flex-col">
        <!--        <div class="group_1 flex-col justify-end">-->
        <!--            <div class="group_2 flex-row">-->
        <!--                <img-->
        <!--                        class="image_1"-->
        <!--                        referrerpolicy="no-referrer"-->
        <!--                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge5dbbc0c09ee25965c5d004ffa4913417713fc5e164b3d30ab47fbea7dd08162"-->
        <!--                />-->
        <!--                <span class="text_1">宸兴数为</span>-->
        <!--                <span class="text_2">梦想宸兴</span>-->
        <!--                <span class="text_3">就业创业</span>-->
        <!--                <span class="text_4">零工市场</span>-->
        <!--                <span class="text_5">社区服务</span>-->
        <!--                <span class="text_6">用户案例</span>-->
        <!--            </div>-->
        <!--            <div class="group_3 flex-row"><div class="group_4 flex-col"></div></div>-->
        <!--        </div>-->
        <div class="group_5 flex-col">
            <div class="block_1 flex-row justify-between">
                <div class="bannerlist">
                    <div class="banner_Info1">
                        <img src="../../assets/img/part1_icon1.png" alt="">
                        <span>梦想宸兴</span>
                    </div>
                    <div class="banner_Info1">
                        <img src="../../assets/img/part1_icon2.png" alt="">
                        <span>技术立业</span>
                    </div>
                    <div class="banner_Info1">
                        <img src="../../assets/img/part1_icon3.png" alt="">
                        <span>创造创新</span>
                    </div>
                </div>
                <!--                <div class="image-text_1 flex-col justify-between">-->
                <!--                    <img-->
                <!--                            class="image_2"-->
                <!--                            referrerpolicy="no-referrer"-->
                <!--                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng85df0ce746ca1888d23cae869916230d2a7ff446c7612b973fc5e289c2f6ed60"-->
                <!--                    />-->
                <!--                    <span class="text-group_1">梦想宸兴</span>-->
                <!--                </div>-->
                <!--                <div></div>-->
                <!--                <div class="image-text_2 flex-col justify-between">-->
                <!--                    <img-->
                <!--                            class="image_3"-->
                <!--                            referrerpolicy="no-referrer"-->
                <!--                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6780f8f831079a2c9c14d9642a890848afdcb068cad5d19855a3bb3b886cf416"-->
                <!--                    />-->
                <!--                    <span class="text-group_2">创造创新</span>-->
                <!--                </div>-->
            </div>
            <div class="block_2 flex-col">
                <div class="box_1 flex-row">
                    <div class="group_6 flex-col">
                        <img
                                class="image_4"
                                referrerpolicy="no-referrer"
                                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2ffc72da039b955f2aaa492cd166df495278687ca665ab70260dbabf91ad5386"
                        />
                        <span class="text_8">我们是谁</span>
                    </div>
                    <div class="group_7 flex-col">
            <span class="paragraph_1"
            >&nbsp;&nbsp;&nbsp;&nbsp;公司成立于2017年，是一家以PDF&nbsp;|&nbsp;OFD电子文档、就业创业、社区数字应用产品和解决方案提供商。公司基于电子文档、就业创业、社区数字应用等创新技术，为用户提供OFD办公套件、云文档、就业创业、零工市场、社区数字等产品和技术服务。<br/><br
            /></span>
                        <span class="text_9"
                        >&nbsp;&nbsp;&nbsp;公司的核心技术和解决方案具有高易用、高效率、高安全等优势，产品与服务覆盖桌面、行业应用、互联网与移动互联网，被广泛应用于政府、企业、机构和个人。公司以“科技创造美好生活”为愿景，以“始终坚持做人们美好生活为导向，感动人心的好产品”为使命，坚持自主创新、务实经营，积极推进电子文档、就业创业、社区数字应用技术创新。</span
                        >
                        <div class="group_8 flex-row justify-between">
                            <div class="group_9 flex-col"></div>
                            <div class="group_10 flex-col"></div>
                        </div>
                        <div class="group_11 flex-row justify-between">
                            <div class="text-group_3 flex-col ">
                                <span class="text_10">公司愿景</span>
                                <span class="text_11"
                                >为政府、企业、个人用户打造领先的家门口数字产品和服务，使居民在家门口享受美好数字生活</span
                                >
                            </div>
                            <div class="text-group_4 flex-col ">
                                <span class="text_12">公司使命</span>
                                <span class="text_13"
                                >以“始终坚持做人们美好生活为导向，感动人心的好产品”为使命</span
                                >
                            </div>
                        </div>
                        <div class="group_12 flex-row justify-between">
                            <div class="box_2 flex-col"></div>
                            <div class="box_3 flex-col"></div>
                        </div>
                        <div class="group_13 flex-row justify-between">
                            <div class="text-group_5 flex-col ">
                                <span class="text_14">文化价值观</span>
                                <span class="text_15"
                                >以奋斗者为荣，为用户和社会创造价值为荣</span
                                >
                            </div>
                            <div class="text-group_6 flex-col justify-between">
                                <span class="text_16">社会责任</span>
                                <span class="text_17"
                                >促就业、助创业、创造家门口美好数字生活</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text_18">
                解决方案和产品
                <div class="block_3 "></div>
            </div>

            <div class="footerBanner">
                <img src="../../assets/img/part1_product1.png" alt="">
                <img src="../../assets/img/part1_product2.png" alt="">
                <img src="../../assets/img/part1_product3.png" alt="">
            </div>
            <div class="text-wrapper_4_nav flex-row">
                <div class="text-wrapper_4 flex-row">
                    <span class="text_22">客服电话：</span>
                    <span class="text_23">029-81027608</span>
                </div>
            </div>

            <div class="block_9 flex-col">
                <div class="image-text_3 flex-row justify-between">
                    <img
                            class="image_22"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng50513a27bc79b08b8a72b5a60b834834e72a2b40f4f587fc3ae7d17a76f888fe"
                    />
                    <span class="text-group_7">秦小业公共就业创业平台</span>
                </div>
                <div class="text-wrapper_5 flex-col">
                    <span class="text_24">
                        <a href="http://www.qinxiaoye.com/xiaoye/#/login" target="_blank">   点击进入</a>
                    </span>
                </div>
            </div>
            <div class="banner">
                <!--                <img src="../../assets/img/banner_part1.png" alt="">-->
            </div>
            <div class="block_10 ">
                <div class="box_9">
                    <img src="../../assets/img/banner_part1.png" alt="">
                </div>
            </div>
            <div class="block_14 flex-col">
                <div class="image-text_4 flex-col justify-between">
                    <img
                            class="label_6"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7b90a19e9b71e6997f90e000707b901c13ef508418c710355ae005143b4a2f6c"
                    />
                    <span class="text-group_8">热线电话</span>
                </div>
                <div class="image-text_5 flex-col justify-between">
                    <img
                            class="label_7"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7f4969e6de005acef4956b8ebf8a4e4b4a264dac03bc26aaa863fb2144820d55"
                    />
                    <span class="text-group_9">关注我们</span>
                </div>
            </div>
            <div class="block_15 flex-row">
                <div class="image-text_6 flex-col justify-between">
                    <img
                            class="image_30"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06f395aaffaceb11c06bb329fd8143667d2f8ae77014a0c71f6854dfbdabcecf"
                    />
                    <div class="text-group_10">
                        <span class="text_27">关注我们二维</span>
                        <span class="text_28">码</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../../utils/commen.css"/>
<style scoped>
    .page {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        width: 100%;
        overflow: hidden;
    }


    .group_5 {
        position: relative;
        width: 100%;
    }

    .block_1 {
        width: 115vh;
        height: 20vh;
        margin: 76vh 0 0 47vh;
    }


    .block_2 {
        height: 100vh;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2a3f3c729cf65c00dc87dbea79cb7d89cdd6f258d40f8f55a5d19c3efdee4964) 100% no-repeat;
        background-size: 100% 100%;
        margin-top: 8vh;
        width: 100%;
    }

    .box_1 {
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/img/part1_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .group_6 {
        position: relative;
        width: 62vh;
        margin-top: 10vh;
    }

    .image_4 {
        width: 55vh;
        height: 88vh;
        margin-top: 1.9vh;
    }

    .text_8 {
        position: absolute;
        left: 36vh;
        top: 0;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 5.1vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 6.7vh;
    }

    .group_7 {
        width: 88vh;
        height: 88vh;
        margin: 10vh 358px 0 97px;
    }

    .paragraph_1 {
        width: 93.5vh;
        height: 11.9vh;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
    }

    .text_9 {
        width: 93.5vh;
        height: 134px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
        margin-top: 3.2vh;
    }

    .group_8 {
        width: 67.5vh;
        height: 2px;
        margin-top: 10.5vh;
    }

    .group_9 {
        width: 200px;
        height: 2px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3387d852126734d3a4cc9af8dc350517f30942703b2606c5212393de91a10c7) -10px -100px no-repeat;
        background-size: 220px 126px;
    }

    .group_10 {
        width: 200px;
        height: 2px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3387d852126734d3a4cc9af8dc350517f30942703b2606c5212393de91a10c7) -10px -100px no-repeat;
        background-size: 220px 126px;
    }

    .group_11 {
        width: 78vh;
        height: 16vh;
        margin-top: 2vh;
    }

    .text-group_3 {
        width: 300px;
        height: 156px;
    }

    .text_10 {
        text-align: left;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 3.1vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        white-space: nowrap;
    }

    .text_11 {
        width: 31vh;
        height: 10.8vh;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
        margin-top: 6px;
    }

    .text-group_4 {
        width: 300px;
        height: 120px;
    }

    .text_12 {
        text-align: left;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 3.1vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        white-space: nowrap;
    }

    .text_13 {
        width: 31vh;
        height: 10.8vh;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
        margin-top: 6px;
    }

    .group_12 {
        width: 640px;
        height: 2px;
        margin-top: 53px;
    }

    .box_2 {
        width: 200px;
        height: 2px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3387d852126734d3a4cc9af8dc350517f30942703b2606c5212393de91a10c7) -10px -100px no-repeat;
        background-size: 220px 126px;
    }

    .box_3 {
        width: 200px;
        height: 2px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3387d852126734d3a4cc9af8dc350517f30942703b2606c5212393de91a10c7) -10px -100px no-repeat;
        background-size: 220px 126px;
    }

    .group_13 {
        width: 78vh;
        height: 16vh;
        margin-top: 2vh;
    }

    .text-group_5 {
        width: 300px;
        height: 120px;
    }

    .text_14 {
        text-align: left;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 3.1vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        white-space: nowrap;
    }

    .text_15 {
        width: 31vh;
        height: 10.8vh;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
        margin-top: 6px;
    }

    .text-group_6 {
        width: 300px;
        height: 120px;
    }

    .text_16 {
        text-align: left;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 3.1vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        white-space: nowrap;
    }

    .text_17 {
        width: 31vh;
        height: 10.8vh;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 2vh;
        font-weight: normal;
        text-align: justify;
        line-height: 3.6vh;
        margin-top: 6px;
    }

    .text_18 {
        width: 100%;
        text-align: center;
        height: 67px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 5vh;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        white-space: nowrap;
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .block_3 {
        width: 70px;
        height: 6px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb6350d88cc0a3e1e471d5f51b84ea4dab47ccddf62376fe0dd52e1f68aceb83d) 100% no-repeat;
        background-size: 100% 100%;
        margin-top: 2vh;
    }

    .text-wrapper_4 {
        width: 226px;
        height: 48px;
        /*background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf7e3c3215fc93ecb4956ac049006a8ab37685aae6ab97b311b0591bfab249abb) 100% no-repeat;*/
        background-image: url("../../assets/img/part3_bg.png");
        background-size: 100% 100%;
        position: absolute;
        right: 5vw;
        bottom: 5vh;
    }

    .text-wrapper_4_nav {
        width: 100%;
        height: 15vh;
        position: relative;
    }

    .text_22 {
        width: 80px;
        height: 21px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 15px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 21px;
        margin: 14px 0 0 12px;
    }

    .text_23 {
        width: 105px;
        height: 21px;
        overflow-wrap: break-word;
        color: rgba(50, 92, 255, 1);
        font-size: 15px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 21px;
        margin: 14px 36px 0 -7px;
    }

    .block_9 {
        width: 100%;
        background-size: 100% 100%;
        background-image: url("../../assets/img/part2_bg.png");
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-text_3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 13vh;
        /*margin: 130px 0 0 671px;*/
    }

    .image_22 {
        width: 4.8vh;
        height: 6vh;
        margin-right: 1vh;
    }

    .text-group_7 {
        color: rgba(255, 255, 255, 1);
        font-size: 5vh;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: right;
        white-space: nowrap;
    }

    .text-wrapper_5 {
        box-shadow: 0px 1px 24px 0px rgba(0, 89, 255, 0.67);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 45px;
        height: 6.5vh;
        width: 21vh;
        margin: 33px 72px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text_24 {
        overflow-wrap: break-word;
        color: rgba(29, 90, 240, 1);
        font-size: 3vh;
        letter-spacing: 1.6666666269302368px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
    }

    .text_24 a {
        text-decoration: none;
        color: #1D5AF0FF;
    }

    .block_10 {
        background-color: rgba(255, 255, 255, 1);
        /*height: 680px;*/
        width: 100%;
        position: absolute;
        left: 0;
        top: -1px;
    }

    .box_9 {
        /*height: 680px;*/
        background-size: 100% 100%;
        width: 100%
    }

    .box_9 img {
        width: 100%;
    }

    .block_14 {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 10px 0px 0px 10px;
        position: absolute;
        right: 0;
        bottom: 20vh;
        width: 86px;
        height: 236px;
        border: 1px solid rgba(217, 217, 217, 1);
        justify-content: flex-center;
    }

    .image-text_4 {
        width: 56px;
        height: 52px;
        margin: 40px 0 0 15px;
    }

    .label_6 {
        width: 27px;
        height: 28px;
        margin-left: 15px;
    }

    .text-group_8 {
        width: 56px;
        height: 18px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 18px;
        margin-top: 6px;
    }

    .image-text_5 {
        width: 56px;
        height: 53px;
        margin: 51px 0 40px 15px;
    }

    .label_7 {
        width: 37px;
        height: 30px;
        margin-left: 13px;
    }

    .text-group_9 {
        width: 56px;
        height: 18px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 18px;
        margin-top: 5px;
    }

    .block_15 {
        position: absolute;
        right: 5vw;
        bottom: 20vh;
        width: 136px;
        height: 142px;
        background-image: url("../../assets/img/part4_bg.png");
        background-size: 100% 100%;
    }

    .image-text_6 {
        width: 94px;
        height: 112px;
        margin: 17px 0 0 17px;
    }

    .image_30 {
        width: 92px;
        height: 92px;
    }

    .text-group_10 {
        width: 94px;
        height: 17px;
        overflow-wrap: break-word;
        font-size: 0;
        letter-spacing: 1.600000023841858px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: right;
        white-space: nowrap;
        line-height: 17px;
        margin-top: 3px;
    }

    .text_27 {
        width: 94px;
        height: 17px;
        overflow-wrap: break-word;
        color: rgba(50, 92, 255, 1);
        font-size: 12px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 17px;
    }

    .text_28 {
        width: 94px;
        height: 17px;
        overflow-wrap: break-word;
        color: rgba(50, 92, 255, 1);
        font-size: 12px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 17px;
    }

    .bannerlist {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .banner_Info1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .banner_Info1 img {
        height: 7.5vh;
        width: 7.5vh;
    }

    .banner_Info1 span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 550;
        font-size: 3vh;
        color: #000000;
        text-align: left;
        font-style: normal;
    }

    .footerBanner {
        width: 100%;
        margin-top: 6vh;
        display: flex;
        justify-content: center;
    }

    .footerBanner img {
        width: 48vh;
        height: 26.5vh;
        margin-right: 2vh;
    }

</style>
