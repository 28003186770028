<template>
    <div class="page flex-col">
        <img class="image_2" src="../../assets/img/job.png" alt="">
        <div class="guarantee">
            <div class="guarantee_title">
                “打零工”对重点和困难人员就业增收具有重要作用
            </div>
            <span class="guarantee_line"></span>
            <div class="jobText">
                2022年人社部等五部门印发《关于加强零工市场建设完善求职招聘服务的意见》指出，要强化零工市场信息服务，完善服务场地设施支撑，拓宽零工信息发布渠道，充分利用政务大厅、基层服务平台、公共就业服务机构、零工服务场所等线下场所和招聘网站、微信微博、手机APP等线上渠道，多渠道、多形式发布零工求职招聘信息。
            </div>
            <div class="jobImg">
                <img src="../../assets/img/job1.png" alt="">
            </div>
            <div class="jobImg">
                <div class="jobImg_left">
                    <img src="../../assets/img/job2.png" alt="">
                </div>
                <div class="jobImg_right">
                    <img src="../../assets/img/job3.png" alt="">
                </div>
            </div>
        </div>
        <!--  第二部分-->
        <div class="PropUp">
            <div class="propText">零工市场数字服务是企业与就业者的重要桥梁</div>
            <span class="guarantee_line "></span>
            <div class="jobText">
                各地政府建立了大量零工市场、零工驿站等，开展零工人员求职登记、就业创业培训服务、零工快速对接服务、困难零工帮扶服务等。受限于传统的摸排登记、张贴宣传形式，采集岗位数量少、参与企业和个人数量少、用工信息匹配效率低、优质岗位不高，需要建立便捷高效的零工信息发布和匹配的数字平台，解决企业和个人不断增长和变化的招工和就业需求。
            </div>
            <div class="jobMarket">
                <img src="../../assets/img/market.png" alt="">
            </div>
        </div>
        <div class="PropUp">
            <div class="propText">灵活用工成为数字经济时代企业用人新选择</div>
            <span class="guarantee_line"></span>
            <div class="jobText">
                各地政府建立了大量零工市场、零工驿站等，开展零工人员求职登记、就业创业培训服务、零工快速对接服务、困难零工帮扶服务等。受限于传统的摸排登记、张贴宣传形式，采集岗位数量少、参与企业和个人数量少、用工信息匹配效率低、优质岗位不高，需要建立便捷高效的零工信息发布和匹配的数字平台，解决企业和个人不断增长和变化的招工和就业需求。
            </div>
            <div class="jobMarket">
                <img src="../../assets/img/require.png" alt="">
            </div>
            <div class="requiredBottom">
                <div class="requireInfo">
                    <div class="PropUp">
                        <div class="requireText">秦小业“多端互联，协同支撑”零工数字化解决方案</div>
                        <span class="requireLine"></span>
                        <div class="requireMarket">
                            <img src="../../assets/img/develop.png" alt="">
                            <div class="requireBox">
                                <div class="require_L">
                                    <div class="require_text_info"> 业务指标监控大屏</div>
                                    <div class="require_text_dec">
                                        实时、运态监控各项用户、企业、岗位指标信息的变化情况
                                    </div>
                                </div>
                                <div class="require_L">
                                    <div class="require_text_info"> 业务管理端</div>
                                    <div class="require_text_dec">
                                        业务管理员登记、管理、审核、查询相关企业、个人及岗位数据
                                    </div>
                                </div>
                                <div class="require_L">
                                    <div class="require_text_info"> 手机小程序</div>
                                    <div class="require_text_dec">
                                        个人、企业、雇主通过小程序实时进行对接和互联
                                    </div>
                                </div>
                                <div class="require_L">
                                    <div class="require_text_info"> 自助数字终端</div>
                                    <div class="require_text_dec">
                                        个人、企业、雇主通过自助数字终端实时查询和了解相关岗位、人员、培训和政策信息。
                                    </div>
                                </div>
                            </div>
                            <div class="require_center">
                                <div class="require_center_text">政府用户</div>
                                <div class="require_center_text">个人或企业用户</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="FooterPropUp">
            <div class="propText">解决方案价值和优势</div>
            <span class="guarantee_line "></span>
            <div class="jobFooter">
                <img src="../../assets/img/footer_img.png" alt="">
                <p>价值和优势</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../../utils/commen.css"/>
<style scoped>

    .page {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        overflow: hidden;
    }

    .jobText {
        width: 62vw;
        height: 11.8vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 6.5vh;
        font-size: 1.95vh;
    }

    .jobImg {
        width: 62vw;
        height: 38vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 6.5vh;
        font-size: 1.95vh;
        margin-bottom: 6.5vh;
        overflow: hidden;
        display: flex;
    }

    .jobImg img {
        width: 100%;
        object-fit: cover;
    }

    .jobImg_left, .jobImg_right {
        width: 31vw;
        height: 48.5vh;
        overflow: hidden;
        display: flex;
    }

    .jobImg_left img, .jobImg_right img {
        width: 100%;
    }

    .jobImg_right {
        margin-left: 20px;
    }

    .guarantee {
        width: 100%;
        margin-top: 8.5vh;
    }

    .guarantee_title {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
    }

    .guarantee_line {
        display: inline-block;
        width: 70px;
        height: 6px;
        background: linear-gradient(129deg, #5FD1FF 0%, #2942FF 100%);
        border-radius: 3px;
    }

    .guarantee_line {
        margin-top: 2.5vh;
    }

    .guarantee_box img {
        width: 36vw;
        height: 43vh;
        margin-top: -4vh;
        margin-left: -17px;
    }

    .propText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
        margin-top: 13vh;
    }


    .PropUp_bg_left img {
        width: 39vw;
        height: 42vh;
    }

    .advantage_bg {
        width: 68vw;
        height: 100vh;
        background-size: 100% 100%;
        margin: 0 auto;
        display: flex;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .advantage_bg img {
        width: 57vw;
        height: 97.5vh;
        margin: 0 auto;
        margin-top: 60px;
    }

    .advantage_text {
        width: 15.5vw;
        height: 6.5vh;
        background: linear-gradient(90deg, #6BFFEC 0%, #FFE968 100%);
        border-radius: 59px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #005BFF;
        line-height: 42px;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -11vh;
    }

    .CoreGroup img {
        width: 100%;
        margin-top: -25vh;
    }

    .argumentInfo img {
        width: 7.1vw;
        height: 30vh;
        margin-left: 1.6vh;
        margin-top: -4vh;
    }

    .jobMarket {
        width: 67.5vw;
        height: 63.8vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        margin-bottom: 69px;
        display: flex;
    }


    .requiredBottom {
        width: 100%;
        height: 157vh;
        background-color: #fff;
        background-image: url("../../assets/img/require_bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: -28.1vh;
    }
    .requireInfo {
        padding-top: 28.1vh;
    }
    .requireText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #ffff;
        font-style: normal;
        margin-top: 6vh;
    }

    .requireLine {
        background-color: #fff !important;
        display: inline-block;
        width: 70px;
        height: 6px;
        border-radius: 3px;
    }

    .requireMarket {
        width: 67.5vw;
        /*height: 63.8vh;*/
        height: 1022px;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        margin-bottom: 69px;
        display: flex;
        flex-direction: column;
    }

    .requireBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .require_L {
        width: 15vw;
        height: 21vh;
        background-color: rgba(99, 0, 255, 0.05);
        border-radius: 25px;
        margin-top: -34vh;
    }

    .require_text_info {
        width: 100%;
        text-align: center;
        ont-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 3.2vh;
        color: #2F0788;
        font-style: normal;
        margin-top: 3.4vh;
        text-indent: 0;
    }

    .require_text_dec {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.9vh;
        color: #000000;
        line-height: 3.5vh;
        text-align: justify;
        font-style: normal;
        text-indent: 0;
        padding: 0 2vh;
        margin-top: 1.2vh;
    }

    .require_center {
        width: 100%;
        display: flex;
    }

    .require_center_text {
        width: 50%;
        text-indent: 0;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 3.1vh;
        color: #2F0788;
        line-height: 30px;
        letter-spacing: 13px;
        font-style: normal;
        margin-top: -5vh;
    }

    .jobFooter {
        width: 100%;
        height: 113vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        display: flex;
        background-image: url("../../assets/img/footer_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
    }

    .jobFooter img {
        margin: 0 auto;
        height: 104.5vh;
    }

    .jobFooter p {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 67px;
        font-style: normal;
        text-align: center;
        margin-top: -15vh;
        font-size: 5.5vh;
    }
</style>
