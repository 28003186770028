<template>
    <div class="page flex-col">
        <img class="image_2" src="../../assets/img/community.png" alt="">
        <div class="guarantee">
            <div class="guarantee_title">
                社区数字化发展中的核心需求和落地难点分析
            </div>
            <span class="guarantee_line"></span>
            <div class="jobImg">
                <div class="jobImg_left">
                    <img src="../../assets/img/com_bg.png" alt="">
                </div>
                <div class="jobImg_right">
                    <img src="../../assets/img/com_bg2.png" alt="">
                </div>
            </div>
            <div class="jobImg">
                <div class="jobImg_left">
                    <img src="../../assets/img/com_bg3.png" alt="">
                </div>
                <div class="jobImg_right">
                    <img src="../../assets/img/com_bg4.png" alt="">
                </div>
            </div>
        </div>
        <!--  第二部分-->
        <div class="PropUp">
            <div class="propText">秦小业“联动创新”数字社区发展和构建思路</div>
            <span class="guarantee_line "></span>
            <div class="jobImgText">
                <div class="jobImg_left_thought">
                    <p class="goal">目标</p>
                    <p class="goalInfo">以社区居民家门口美好便捷的生活服务为目标</p>
                </div>
                <div class="jobImg_right_thought">
                    <p class="goal">核心</p>
                    <p class="goalInfo">以社区自身业务发展和居民服务需求为核心</p>
                </div>
            </div>
            <div class="jobImgText">
                <div class="jobImg_left_thought">
                    <p class="goal">牵引</p>
                    <p class="goalInfo">以党建、就业、创业、零工、养老等联动化的服务为牵引</p>
                </div>
                <div class="jobImg_right_thought">
                    <p class="goal">支撑载体</p>
                    <p class="goalInfo">以数字屏、小程序、自助化等数字化产品为支撑载体</p>
                </div>
            </div>
        </div>
        <div class="jobImgBottom">
            <div class="jobBox">
                <div class="jobTipsOne">
                    <p class="TipsTitle">社区公告、公示、简介、活动、政策、志愿者等信息发布</p>
                    <p class="TipsInfo">社区自身工作和业务发展需要</p>
                </div>
                <div class="jobTipsTow">
                    <p class="TipsTitle">以党建、就业、创业、零工、养老等公共服务为牵引</p>
                    <p class="TipsInfo">与周边居民、企业、商户的互动互信</p>
                </div>
                <div class="jobTipsThree">
                    <p class="TipsTitle">构建家门口数字应用开放平台，吸入更多创新数字应用和服务
                    </p>
                    <p class="TipsInfo">居民在家门口享受到便捷、丰富的数字服务</p>
                </div>
            </div>
        </div>
        <div class="PropUp">
            <div class="propText">
                自助化、开放化是 <br>
                创新整合社区数字服务的“突破口”和“催化剂”
            </div>
            <span class="guarantee_line"></span>
            <div class="jobText">
                以数字屏、小程序、自助机等自助化系统为载体，整合创新服务形式和内容，构建开放社区数字服务生态，吸引更多优秀创新应用服务落地，促进社区数字化服务产业生态协同发展。
            </div>
            <div class="jobMarket">
                <img src="../../assets/img/com_footer.png" alt="">
                <div class="footer_tips">
                    <p>构建开放的社区数字服务生态</p>
                    <p>促进社区数字化服务产业生态协同发展</p>
                </div>
            </div>
        </div>
        <div class="PropUp">
            <div class="propText">“三化协同、多态服务、协同创新”社区服务数字化解决方案
            </div>
            <span class="guarantee_line "></span>
            <div class="synergy">
                <img src="../../assets/img/synergy.png" alt="">
                <div class="synergyInfo">
                    <p>自动化</p>
                    <p>联动化</p>
                    <p>开放化</p>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../../utils/commen.css"/>
<style scoped>

    .page {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        overflow: hidden;
    }

    .jobText {
        width: 62vw;
        height: 11.8vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 6.5vh;
        font-size: 1.95vh;
    }

    .jobImg {
        width: 62vw;
        height: 37.7vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        /*margin-top: 6.5vh;*/
        font-size: 1.95vh;
        margin-bottom: 2vh;
        overflow: hidden;
        display: flex;
    }

    .jobImgText {
        width: 64vw;
        height: 24.7vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        font-size: 1.95vh;
        margin-bottom: 2vh;
        overflow: hidden;
        display: flex;
    }

    .jobImg img {
        width: 100%;
        object-fit: cover;
    }

    .jobImg_left, .jobImg_right {
        width: 30.7vw;
        height: 38vh;
        overflow: hidden;
        display: flex;
    }

    .jobImg_left img, .jobImg_right img {
        width: 100%;
    }

    .jobImg_right {
        margin-left: 20px;
    }

    .guarantee {
        width: 100%;
        margin-top: 8.5vh;
    }

    .guarantee_title {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
    }

    .guarantee_line {
        display: inline-block;
        width: 70px;
        height: 6px;
        background: linear-gradient(129deg, #5FD1FF 0%, #2942FF 100%);
        border-radius: 3px;
        margin-bottom: 6.5vh;
    }

    .guarantee_line {
        margin-top: 2.5vh;
    }

    .guarantee_box img {
        width: 36vw;
        height: 43vh;
        margin-top: -4vh;
        margin-left: -17px;
    }

    .propText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
        margin-top: 13vh;
    }


    .PropUp_bg_left img {
        width: 39vw;
        height: 42vh;
    }

    .advantage_bg {
        width: 68vw;
        height: 100vh;
        background-size: 100% 100%;
        margin: 0 auto;
        display: flex;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .advantage_bg img {
        width: 57vw;
        height: 97.5vh;
        margin: 0 auto;
        margin-top: 60px;
    }

    .CoreGroup img {
        width: 100%;
        margin-top: -25vh;
    }

    .argumentInfo img {
        width: 7.1vw;
        height: 30vh;
        margin-left: 1.6vh;
        margin-top: -4vh;
    }

    .jobMarket {
        width: 67.5vw;
        height: 119.5vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        margin-bottom: 69px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 15vh;
    }

    .jobMarket img {
        height: 119.5vh;
        margin: 0 auto;
    }

    .requiredBottom {
        width: 100%;
        /*height: 1579px;*/
        height: 157vh;
        background-color: #fff;
        background-image: url("../../assets/img/require_bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: -28.1vh;
    }

    .requireInfo {
        padding-top: 28.1vh;
    }

    .requireText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #ffff;
        font-style: normal;
        margin-top: 6vh;
    }

    .requireLine {
        background-color: #fff !important;
        display: inline-block;
        width: 70px;
        height: 6px;
        border-radius: 3px;
    }

    .requireMarket {
        width: 67.5vw;
        /*height: 63.8vh;*/
        height: 1022px;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        margin-bottom: 69px;
        display: flex;
        flex-direction: column;
    }

    .requireBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .require_L {
        width: 15vw;
        height: 21vh;
        background-color: rgba(99, 0, 255, 0.05);
        border-radius: 25px;
        margin-top: -34vh;
    }

    .require_text_info {
        width: 100%;
        text-align: center;
        ont-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 3.2vh;
        color: #2F0788;
        font-style: normal;
        margin-top: 3.4vh;
        text-indent: 0;
    }

    .require_text_dec {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.9vh;
        color: #000000;
        line-height: 3.5vh;
        text-align: justify;
        font-style: normal;
        text-indent: 0;
        padding: 0 2vh;
        margin-top: 1.2vh;
    }

    .require_center {
        width: 100%;
        display: flex;
    }

    .require_center_text {
        width: 50%;
        text-indent: 0;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 3.1vh;
        color: #2F0788;
        line-height: 30px;
        letter-spacing: 13px;
        font-style: normal;
        margin-top: -5vh;
    }

    .jobFooter {
        width: 100%;
        height: 113vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        display: flex;
        background-image: url("../../assets/img/footer_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
    }

    .jobFooter img {
        margin: 0 auto;
        height: 104.5vh;
    }

    .jobFooter p {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 67px;
        font-style: normal;
        text-align: center;
        margin-top: -15vh;
        font-size: 5.5vh;
    }

    .jobImg_left_thought, .jobImg_right_thought {
        width: 30.2vw;
        height: 21vh;
        background: linear-gradient(270deg, #FEFEFF 0%, #E7F1FF 100%);
        border-radius: 10px;
        border: 2px solid #3562FF;
        margin: 0 2vh;
        box-sizing: border-box;
        padding: 0 40px;
    }

    .goal {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5vh;
        color: #000000;
        line-height: 7vh;
        text-align: left;
        font-style: normal;
        margin-top: 20px;
    }

    .goalInfo {
        margin-top: 5px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 3.1vh;
        color: #000000;
        line-height: 4.8vh;
        text-align: left;
        font-style: normal;
    }

    .jobImgBottom {
        width: 100%;
        height: 96vh;
        background-image: url("../../assets/img/comjob_bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
    }

    .jobBox {
        width: 59vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
    }

    .jobTipsOne, .jobTipsTow, .jobTipsThree {
        width: 16vw;
        height: 18vh;
        background: linear-gradient(179deg, rgba(53, 98, 255, 0.09) 0%, rgba(32, 0, 238, 0) 100%);
        border-radius: 8px;
    }

    .jobTipsOne {
        margin-top: 28vh;
        margin-left: 3vw;
    }

    .jobTipsTow {
        margin-top: 17vh;
        margin-left: 5.5vw;
    }

    .jobTipsThree {
        margin-top: 10px;
        margin-left: 5.5vw;
    }

    .TipsTitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 2.7vh;
        color: #325CFF;
        line-height: 4.5vh;
        text-align: left;
        font-style: normal;
        box-sizing: border-box;
        padding: 20px 14px;
    }

    .TipsInfo {
        box-sizing: border-box;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        padding: 0 14px;

    }

    .footer_tips {
        width: 31.5vw;
        height: 16.5vh;
        background: linear-gradient(180deg, #0968FC 0%, #4AA3FD 100%);
        border-radius: 78px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 3.2vh;
        color: #FFFFFF;
        line-height: 4.5vh;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: -11vh;
    }

    .synergy {
        width: 100%;
        height: 132.2vh;
        margin: 0 auto;
        background-image: url("../../assets/img/footer_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .synergy img {
        width: 84vw;
    }

    .synergyInfo {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .synergyInfo p {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.2vh;
        color: #325CFF;
        line-height: 67px;
        text-align: right;
        font-style: normal;
        margin: 30px 30px;
    }
</style>
