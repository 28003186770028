import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "@/views/home";
import HomeView from '../views/HomeView.vue'
import dream from "@/views/publicViews/dream";
import employment from "@/views/publicViews/employment";
import OddJob from "@/views/publicViews/OddJob";
import community from "@/views/publicViews/community";
import contact from "@/views/publicViews/contact";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    children: [
      {
        path: '/dream',
        name: 'dream',
        component: dream,
      }, {
        path: '/employment',
        name: 'employment',
        component: employment,
      }, {
        path: '/OddJob',
        name: 'OddJob',
        component: OddJob,
      }, {
        path: '/community',
        name: 'community',
        component: community,
      }, {
        path: '/contact',
        name: 'contact',
        component: contact,
      },
    ]
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
