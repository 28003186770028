<template>
    <div class="page flex-col">
        <img class="image_2" src="../../assets/img/caseUser.png" alt="">
        <div class="guarantee">
            <div class="guarantee_title">
                合作伙伴
            </div>
            <span class="guarantee_line"></span>
            <div class="jobImg">
                <img src="../../assets/img/caseImg.png" alt="">
            </div>
        </div>
        <!--  第二部分-->
        <div class="FooterPropUp">
            <div class="jobFooter">
                <div class="footer_nav">
                    <img class="partial" src="../../assets/img/Partial.png" alt="">
                    <div class="navInfo">
                        <div class="navInfo_text">
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                西安市新城区
                            </div>
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                秦创原人才大市场
                            </div>
                        </div>
                        <div class="navInfo_text">
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                西安市高新区
                            </div>
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                渭南市临渭区
                            </div>
                        </div>
                        <div class="navInfo_text">
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                西安市鄠邑区
                            </div>
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                渭南市高新区
                            </div>
                        </div>
                        <div class="navInfo_text">
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                西安市雁塔区
                            </div>
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                商洛市商州区
                            </div>
                        </div>
                        <div class="navInfo_text">
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                西安市未央区
                            </div>
                            <div class="navInfo_text_info">
                                <img src="../../assets/img/number.png" alt="">
                                更多合作…
                            </div>
                        </div>
                    </div>
                </div>
                <div class="PropUp">
                    <div class="propText">定制化的解决方案，满足您的实际业务需求</div>
                    <span class="guarantee_line"></span>
                    <div class="trial">
                        联系我们
                    </div>
                    <div class="firmName">
                        <div class="companyName">公司地址：西安市高新区锦业一路10号中投国际A座21层2101室</div>
                        <div class="companyName">客户服务电话：029-81027608</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      dialogVisible: false
    };
  },
  methods: {
    ContentUs() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  },
};
</script>
<style src="../../utils/commen.css"/>
<style scoped>

    .page {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        overflow: hidden;
    }

    .jobImg {
        width: 75vw;
        height: 118vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 6.5vh;
        font-size: 1.95vh;
        margin-bottom: 6.5vh;
        overflow: hidden;
        display: flex;
    }

    .jobImg img {
        width: 100%;
        object-fit: cover;
    }

    .jobImg_left img, .jobImg_right img {
        width: 100%;
    }

    .guarantee {
        width: 100%;
        margin-top: 8.5vh;
    }

    .guarantee_title {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
    }

    .guarantee_line {
        display: inline-block;
        width: 70px;
        height: 6px;
        background: linear-gradient(129deg, #5FD1FF 0%, #2942FF 100%);
        border-radius: 3px;
        margin: 0 auto;
    }

    .guarantee_line {
        margin-top: 2.5vh;
    }

    .guarantee_box img {
        width: 36vw;
        height: 43vh;
        margin-top: -4vh;
        margin-left: -17px;
    }


    .PropUp_bg_left img {
        width: 39vw;
        height: 42vh;
    }

    .advantage_bg img {
        width: 57vw;
        height: 97.5vh;
        margin: 0 auto;
        margin-top: 60px;
    }

    .CoreGroup img {
        width: 100%;
        margin-top: -25vh;
    }

    .argumentInfo img {
        width: 7.1vw;
        height: 30vh;
        margin-left: 1.6vh;
        margin-top: -4vh;
    }

    .jobFooter {
        width: 100%;
        height: 113vh;
        margin: 0 auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
        text-indent: 1.5vw;
        margin-top: 60px;
        font-size: 1.95vh;
        display: flex;
        background-image: url("../../assets/img/footer_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .footer_nav {
        width: 60.5vw;
        height: 73.8vh;
        margin: 0 auto;
        padding: 0 3.5vw;
        position: relative;
        background-color: #fff;
    }

    .navInfo {
        margin-top: 13.8vh;
        position: relative;
    }

    .navInfo_text {
        width: 53.6vw;
        height: 11.3vh;
        display: flex;
        align-items: center;
    }

    .navInfo_text:nth-child(odd) {
        background-color: rgba(228, 237, 254, 1);
    }

    .navInfo_text:nth-child(even) {
        background-color: rgba(228, 237, 254, 0.36);
    }

    .navInfo_text_info {
        width: 50%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 50px;
    }

    .navInfo_text_info img {
        width: 2.5vw;
    }

    .partial {
        height: 14.8vh;
        position: absolute;
        top: 0;
        left: -4vh;
    }

    .propText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
        margin-top: 13vh;
    }

    .PropUp {
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .trial {
        width: 19vw;
        height: 15vh;
        background-image: url("../../assets/img/trial.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #FFFFFF;
        line-height: 42px;
        letter-spacing: 1px;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: 0;
        cursor: pointer;
    }

    .trial a {
        text-decoration: none;
        color: #fff;
    }

    .firmName{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 88px;
        flex-direction: column;
    }
</style>
