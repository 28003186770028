<template>
    <div class="page flex-col">
        <div class="group_14 flex-col">
            <div class="section_6 flex-row">
                <div class="image-text_7 flex-row justify-between">
                    <img class="image_31" src="../assets/img/firmlogo.png" alt="">
                </div>
                <span class="text_29" @click="getdream"> 梦想宸兴</span>
                <span class="text_30" @click="getTake">就业创业</span>
                <span class="text_31" @click="getmarket">零工市场</span>
                <span class="text_32" @click="getCommunity">社区服务</span>
                <span class="text_33" @click="getUser">用户案例</span>
                <span class="text_34">关注我们</span>
                <div class="image-text_8 flex-row justify-between">
                    <img
                            class="thumbnail_17"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaccc7eecca8f08ca4574dc7d5c3fe75d0582075c097b025a706acd8420e875e5"
                    />
                    <span class="text-group_12">微信</span>
                </div>
            </div>
            <div class="section_7 flex-row">
                <img
                        class="image_32"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng88da60f8a2a8570a93bb3ecc85a635aaea41a689b8c48edb6bcf297fd5f896e1"
                />


                <div class="section_8 flex-col">
                </div>
                <img
                        class="label_8"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeb915c20bd24a1983110ac7ab34748794140777fb49fc5160ca6e97116ce7a73"
                />
            </div>
            <img
                    class="image_33"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcf0edecb7f904902a1cc79f679b9d186a3718c3c92264fac5a4ef6faeadfe9c3"
            />
            <span class="text_35"
            >Copyright&nbsp;©&nbsp;2023&nbsp;宸兴数为（西安）信息科技有限公司</span
            >
            <div class="image-text_9 flex-row justify-between">
                <a target="_blank" class="record" href="https://beian.miit.gov.cn/">
                    <span class="text-group_13">&nbsp;&nbsp;陕ICP备2023007562号-2</span>
                </a>
                <div class="box_10 flex-col"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {
    getdream() {
      this.$emit('childByValue', '/dream')
    },
    getTake() {
      this.$emit('childByValue', '/employment')
    },
    getmarket() {
      this.$emit('childByValue', '/OddJob')

    },
    getCommunity() {
      this.$emit('childByValue', '/community')

    },
    getUser() {
      this.$emit('childByValue', '/contact')

    }
  },
};
</script>
<style src="../utils/commen.css"/>
<style scoped>
    .page {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        width: 100%;
        overflow: hidden;
    }


    .group_14 {
        background-color: rgba(19, 27, 48, 1);
        width: 100%;
        height: 326px;
        margin-top: -1px;
    }

    .section_6 {
        width: 1170px;
        height: 48px;
        /*margin: 50px 0 0 360px;*/
        margin: 0 auto;
        margin-top: 50px;
    }

    .image-text_7 {
        width: 197px;
        height: 48px;
    }

    .image_31 {
        height: 5.1vh;
    }

    .text_29 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: right;
        white-space: nowrap;
        line-height: 22px;
        margin: 1.5vh 0 0 10.3vh;
        cursor: pointer;
    }

    .text_30 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 13px 0 0 50px;
        cursor: pointer;
    }

    .text_31 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 13px 0 0 50px;
        cursor: pointer;
    }

    .text_32 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 13px 0 0 50px;
        cursor: pointer;
    }

    .text_33 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 13px 0 0 50px;
        cursor: pointer;
    }

    .text_34 {
        width: 64px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 13px 0 0 23vh;
    }

    .image-text_8 {
        width: 47px;
        height: 15px;
        margin: 17px 0 0 24px;
    }

    .thumbnail_17 {
        width: 18px;
        height: 15px;
    }

    .text-group_12 {
        width: 24px;
        height: 12px;
        overflow-wrap: break-word;
        color: rgba(127, 130, 138, 1);
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 12px;
        margin-top: 1px;
    }

    .section_7 {
        width: 86.5vh;
        height: 5.7vh;
        margin: 1px 0 0 70.6vh;
    }

    .image_32 {
        width: 310px;
        height: 29px;
        margin-top: 2.8vh;
    }

    .section_8 {
        height: 48px;
        background-image: url("../assets/img/guanzhu.png");
        background-size: 100% 100%;
        margin-left: 46vh;
        width: 48px;
    }

    .image-wrapper_16 {
        background-color: rgba(255, 255, 255, 1);
        height: 9px;
        width: 9px;
        margin: 21px 0 0 20px;
    }

    .thumbnail_18 {
        width: 9px;
        height: 8px;
        margin-top: -1px;
    }

    .label_8 {
        width: 48px;
        height: 48px;
        margin-left: 24px;
    }

    .image_33 {
        width: 1200px;
        height: 3px;
        margin: 32px 0 0 362px;
    }

    .text_35 {
        margin-top: 20px;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        /*margin: 40px 0 0 661px;*/
    }

    .image-text_9 {
        width: 100%;
        height: 24px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-group_13 {
        width: 150px;
        height: 12px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 12px;
        margin-top: 6px;
    }

    .box_10 {
        border-radius: 3px;
        background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d93e67f2500b495c8be3851944702a68_mergeImage.png);
        width: 66px;
        height: 24px;
    }

    .record{
        text-decoration: none;
        margin-right: 20px;
    }
</style>
