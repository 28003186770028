import { render, staticRenderFns } from "./community.vue?vue&type=template&id=12f0ecaa&scoped=true"
import script from "./community.vue?vue&type=script&lang=js"
export * from "./community.vue?vue&type=script&lang=js"
import style0 from "../../utils/commen.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./community.vue?vue&type=style&index=1&id=12f0ecaa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f0ecaa",
  null
  
)

export default component.exports