<template>
    <div class="page flex-col">
        <img class="image_2" src="../../assets/img/banner_part2.png" alt="">
        <div class="guarantee">
            <div class="guarantee_title">
                就业驿站对重点和困难人员起重要保障作用
            </div>
            <span class="guarantee_line"></span>
            <div class="block_bg">
                <div class="guarantee_left">
                    <p class="guarantee_paragraph1">
                        习近平总书记指出，就业是最大的民生工程、民心工程、根基工程，是社会稳定的主要保障，必须抓紧抓实抓好。
                    </p>
                    <p class="guarantee_paragraph2">
                        在人力资源和社会保障部的坚强领导和要求下，全国正在建立上万个家门口就业创业驿站，为居民提供家门口求职服务、培训服务、创业服务、维权服务和补贴服务，对重点和困难就业人员的就业起到了关键保障和促进作用，有力助推区域经济社会高质量发展。
                    </p>
                </div>
                <div class="guarantee_right">
                    <div class="guarantee_box">
                        <img src="../../assets/img/block_photo.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!--  第二部分-->
        <div class="PropUp">
            <div class="propText">数字屏是就业驿站服务落地的重要支撑</div>
            <span class="guarantee_line"></span>
            <div class="PropUp_bg first">
                <div class="PropUp_bg_left">
                    <img src="../../assets/img/PropUp1.png" alt="">
                </div>
                <div class="PropUp_bg_right">
                    <p class="PropUp_title">就业创业数字屏</p>
                    <p class="PropUp_text">
                        包含岗位地图、职位搜索、重点群体、热门岗位、创业帮扶、技能培训、视频播报、政策解读、通知公告、人社地图等功能模块。
                    </p>
                </div>
            </div>

            <div class="PropUp_bg">
                <div class="PropUp_bg_right">
                    <p class="PropUp_title">社区服务数字屏
                    </p>
                    <p class="PropUp_text">
                        在就业创业服务驿站版本功能上增加公示公告、惠民政策、社区简介、社区活动、爱心救助、视频播报等功能模块。
                    </p>
                </div>
                <div class="PropUp_bg_left">
                    <img src="../../assets/img/PropUp1.png" alt="">
                </div>
            </div>

            <div class="PropUp_bg first">
                <div class="PropUp_bg_left">
                    <img src="../../assets/img/PropUp3.png" alt="">
                </div>
                <div class="PropUp_bg_right">
                    <p class="PropUp_title">零工市场数字屏</p>
                    <p class="PropUp_text">
                        包含我要找人、我要找活、培训需求、政策解读、扫描二维码等功能模块。
                    </p>
                </div>
            </div>
        </div>
        <!--  第三部分-->
        <div class="advantage">
            <div class="propText">数字屏是创新人社数字服务“新载体、新窗口”</div>
            <span class="guarantee_line"></span>
            <div class="advantage_bg">
                <img src="../../assets/img/advantage.png" alt="">
                <span class="advantage_text">人社部门新模式</span>
            </div>
        </div>
        <div class="CoreGroup">
            <img src="../../assets/img/Core_bg.png" alt="">
        </div>
        <div class="advantage">
            <div class="propText">就业数字屏产品型号</div>
            <span class="guarantee_line"></span>
        </div>
        <div class="product">
            <div class="product_info">
                <p class="product_title">横屏型</p>
                <p class="product_screen">秦小业家门口就业创业数字屏</p>
                <p class="product_number">产品型号：SN-HP-50</p>
                <p class="product_feature">
                    产品功能：秦小业家门口就业创业数字屏标准版产品基本功能包含岗位地图、职位搜索、重点群体、热门岗位、创业帮扶、技能培训、视频播报、政策解读、通知公告、人社地图等功能模块
                </p>
            </div>
            <div class="product_model">
                <p class="product_model_title">竖屏型</p>
                <p class="product_model_text">
                    产品名称：秦小业家门口就业创业数字屏
                </p>
                <p class="product_model_text"> 产品型号：SN-SP-43</p>
            </div>
        </div>
        <!--        底部参数-->
        <div class="argument">
            <div class="argumentInfo">
                <div class="argumentInfo_left">
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">适用场景</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">室内安装</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">机身材质</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">镀锌板</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">表面材质</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">钢化玻璃</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">面板尺寸</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">50或43 英寸LG电容触摸屏</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">分辨率</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">1920*1080</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">系统版本</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">Android 11</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">系统运行内存</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">4G</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">系统硬盘</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">64G</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">身份证读卡器</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">LTE-S2</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">摄像头</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">双目摄像头500W 像素</div>
                    </div>
                </div>
                <div class="argumentInfo_rigth">
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">麦克风</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">外设麦克风</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">USB端口</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">USB*2</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">网络通信</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">RJ45*1</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">高清端口</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">HDMI*1</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">输入电压</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">AC110-220V</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">网络连接</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">WiFi，有线，4G</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">触摸方案</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">电容G+G</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">工作环境</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">温度‐0℃~55℃ /湿度10%RH~90%RH</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">使用寿命</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">＞80000小时</div>
                    </div>
                    <div class="argumentInfo_text">
                        <div class="argumentInfo_title">安装方式</div>
                        <div class="argumentInfo_line"></div>
                        <div class="argumentInfo_materials">底部装4个可移动的轮子落地摆放</div>
                    </div>
                </div>
                <img src="../../assets/img/materials.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../../utils/commen.css"/>
<style scoped>

    .page {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        overflow: hidden;
    }

    .guarantee {
        width: 100%;
        margin-top: 8.5vh;
    }

    .guarantee_title {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
    }

    .guarantee_line {
        display: inline-block;
        width: 70px;
        height: 6px;
        background: linear-gradient(129deg, #5FD1FF 0%, #2942FF 100%);
        border-radius: 3px;
    }

    .guarantee_line {
        margin-top: 2.5vh;
    }

    .block_bg {
        width: 68vw;
        height: 51.5vh;
        background-size: 100% 100%;
        background-image: url("../../assets/img/block_bg.png");
        margin: 0 auto;
        display: flex;
        margin-top: 6vh;
    }

    .guarantee_left {
        width: 22vw;
        height: 40vh;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        margin-top: 6.7vh;
        margin-left: 54px;
        padding: 3vh 17px 17px 17px;
    }

    .guarantee_paragraph1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 1.9vh;
        color: #FFFFFF;
        line-height: 4vh;
        text-align: justify;
        font-style: normal;
        text-indent: 3vh;
    }

    .guarantee_paragraph2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 1.9vh;
        color: #FFFFFF;
        line-height: 4vh;
        text-align: justify;
        font-style: normal;
        text-indent: 3vh;
    }

    .guarantee_right {
        width: 32vw;
        height: 40vh;
        background-color: RGBA(160, 163, 255, 1);
        border-radius: 30px;
        margin-top: 67px;
        margin-left: 5vw;
    }

    .guarantee_box {
        width: 34vw;
        height: 41vh;
        background-color: RGBA(222, 223, 255, 1);
        border-radius: 30px;
        margin-top: -3vh;
        margin-left: -17px;
    }

    .guarantee_box img {
        width: 36vw;
        height: 43vh;
        margin-top: -4vh;
        margin-left: -17px;
    }

    .propText {
        width: 100%;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #000000;
        font-style: normal;
        margin-top: 13vh;
    }

    .PropUp_bg {
        width: 68vw;
        height: 42vh;
        background-size: 100% 100%;
        margin: 0 auto;
        display: flex;
        margin-bottom: 55px;
    }

    .first {
        margin-top: 6vh;
    }

    .PropUp_bg_left img {
        width: 39vw;
        height: 42vh;
    }

    .PropUp_bg_right {
        width: 26vw;
        margin-left: 3.15vw;
        height: 42vh;
        box-sizing: border-box;
    }

    .PropUp_title {
        width: 100%;
        text-align: left;
        padding-top: 121px;
        margin-bottom: 12px;
    }

    .PropUp_title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #325CFF;
        line-height: 4vh;
        text-align: left;
        font-style: normal;
    }

    .PropUp_text {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.9vh;
        color: #000000;
        line-height: 3.6vh;
        text-align: justify;
        font-style: normal;
        width: 23vw;
    }


    .advantage_bg {
        width: 68vw;
        height: 100vh;
        background-size: 100% 100%;
        margin: 0 auto;
        display: flex;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .advantage_bg img {
        width: 57vw;
        height: 97.5vh;
        margin: 0 auto;
        margin-top: 60px;
    }

    .advantage_text {
        width: 15.5vw;
        height: 6.5vh;
        background: linear-gradient(90deg, #6BFFEC 0%, #FFE968 100%);
        border-radius: 59px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #005BFF;
        line-height: 42px;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -11vh;
    }

    .CoreGroup {
        width: 100%;
    }

    .CoreGroup img {
        width: 100%;
        margin-top: -25vh;
    }

    .product {
        width: 100%;
        height: 185vh;
        background-image: url("../../assets/img/product.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 150px;
    }

    .product_info {
        width: 31vw;
        height: 47vh;
        margin-left: 18.8vw;
    }

    .product_title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #325CFF;
        line-height: 67px;
        text-align: left;
        font-style: normal;
        font-size: 5.1vh;
    }

    .product_screen, .product_number, .product_feature {
        text-align: left;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 3.25vh;
        color: #000000;
        line-height: 52px;
        text-align: justify;
    }

    .product_model {
        margin-top: 57.1vh;
        width: 30vw;
        margin-left: 47.5vw;
    }

    .product_model_title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 5.1vh;
        color: #FFFFFF;
        line-height: 67px;
        text-align: left;
        font-style: normal;
    }

    .product_model_text {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 3.2vh;
        color: #FFFFFF;
        line-height: 52px;
        text-align: justify;
        font-style: normal;
    }

    .argument {
        margin-top: 40px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(135, 182, 255, 0.35) 100%);
        padding-bottom: 150px;
        box-sizing: border-box;
    }

    .argumentInfo {
        width: 62.5vw;
        height: 67.15vh;
        background-color: #fff;
        margin: 0 auto;
        display: flex;
        padding-top: 40px;
        padding-left: 60px;
        box-sizing: border-box;
        margin-top: 100px;
    }

    .argumentInfo_left {
        width: 20.8vw;
    }

    .argumentInfo_text {
        display: flex;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.88vh;
        color: #000000;
        text-align: center;
        font-style: normal;
    }

    .argumentInfo_text:nth-child(odd) {
        width: 100%;
        height: 6vh;
        background-color: rgba(228, 237, 254, 1);
    }

    .argumentInfo_text:nth-child(even) {
        width: 100%;
        height: 6vh;
        background-color: rgba(228, 237, 254, 0.5);
    }

    .argumentInfo_title {
        width: 7vw;
        text-align: right;
        margin-right: 14px;
    }

    .argumentInfo_materials {
        text-align: left;
        margin-left: 0.8vw;
    }

    .argumentInfo_line {
        width: 5px;
        height: 57px;
        background-color: #fff;
    }

    .argumentInfo_rigth {
        width: 25vw;
        margin-left: 3.15vw
    }

    .argumentInfo img {
        width: 7.1vw;
        height: 30vh;
        margin-left:1.6vh;
        margin-top: -4vh;
    }

</style>
